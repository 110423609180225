import { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useDescription, useTitle, useCanonical } from './dependencies'

export const ShopListSeoHead: FC = () => {
  const title = useTitle()
  const description = useDescription()
  const canonical = useCanonical()

  return (
    <NextSeo title={title} description={description} canonical={canonical} />
  )
}
